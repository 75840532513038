body {
  font-family: 'Open Sans', sans-serif !important;
  background-image: url('../src/img/background.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff !important;
}

blockquote {
  color: #fff
}

.color-white {
  color: #fff
}

p {
  font-size: 18px;
}

.thank-you-card {
  margin-top: 125px;
  background-color: #ffffff;
  color: #000000;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 60px 40px;
  border-radius: 10px;
  text-align: center;
}

@media (max-width: 960px) {
  p {
    font-size: 16px;
  }

  .thank-you-card{
    width: 86%!important;
    padding: 60px 25px!important;
  }

  .hero-image {
    margin-top: 25px;
    padding: 0;
    padding-left: 30px;
  }

  .form {
    width: 100% !important;
  }

  .footer-designer {
    flex-wrap: wrap;
    justify-content: center !important;
  }

  .footer-designer .image-5-parent {
    margin-top: 50px;
  }

  .copyright-footer {
    text-align: center;
    margin-top: 35px;
  }
}

header nav img {
  width: 230px;
  margin-top: 30px;
}

h1 {
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  color: #fff;
}

@media (max-width: 960px) {
  h1 {
    font-size: 40px;
  }
}

h1 span {
  color: #00FF26;
}

h2 {
  color: #fff;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 960px) {
  h2 {
    font-size: 30px;
  }
}

.box {
  background-color: #262295;
  border-radius: 25px;
  padding: 55px 25px !important;
}

ul {
  list-style: none;
  /* Varsayılan liste stilini kaldırır */
  padding: 0;
  margin: 0;
}

.list-item {
  display: flex;
  align-items: center;
  /* Dikeyde ortalama yapar */
  margin-bottom: 20px;
  /* Liste öğeleri arasındaki boşluk */
}

.list-item svg {
  flex-shrink: 0;
  /* SVG'nin boyutunun küçülmesini engeller */
  margin-right: 15px;
  /* SVG ile metin arasındaki boşluk */
}

.list-item p {
  margin: 0;
  /* Varsayılan paragraf boşluğunu kaldırır */
  /* Gerekirse metin stilini burada özelleştirebilirsiniz */
}

h2.info {
  color: #fff;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0;
}

@media (max-width: 960px) {
  h2.info {
    font-size: 30px;
  }
}

h3 {
  font-size: 25px;
  color: #fff
}

@media (max-width: 960px) {
  h3 {
    font-size: 20px;
  }
}

.button {
  position: relative;
  text-transform: capitalize;
  position: relative;
  box-shadow: 0px 4px 100px rgba(0, 255, 51, 0.5);
  border-radius: 4px;
  background: linear-gradient(-90deg, #15f32d, #358a17);
  width: 320px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 14px 70px;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  color: #fff;
  transition: all .2s;
  cursor: pointer;
  text-decoration: none !important;
}

@media (max-width: 960px) {
  button {
    font-size: px;
  }
}

.button:hover {
  box-shadow: 0px 4px 100px rgba(0, 255, 51, 0.697);
  background: linear-gradient(-90deg, #0afb22, #3fb515);
  color: #fff;
}

.info {
  color: #888;
  font-weight: 200;
}

.img1 {
  text-align: center;
  max-width: 700px;
  width: 100%;
}

.img2 {
  text-align: center;
  max-width: 680px;
  width: 100%;
  border-radius: 15px;
  float: right;
}

.img3 {
  text-align: center;
  max-width: 680px;
  width: 100%;
  border-radius: 15px !important;
  float: left;
}

.box-button {
  position: relative;
  text-transform: capitalize;
  position: relative;
  box-shadow: 0px 4px 100px rgba(0, 255, 51, 0.5);
  border-radius: 4px;
  background: linear-gradient(-90deg, #15f32d, #358a17);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 14px 70px;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  color: #fff;
  transition: all .2s;
  cursor: pointer;
  text-decoration: none !important;
}

.box-button:hover {
  box-shadow: 0px 4px 100px rgba(0, 255, 51, 0.697);
  background: linear-gradient(-90deg, #0afb22, #3fb515);
  color: #fff;
}



.form {
  width: 500px;
  position: relative;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.13);
  border: 1px solid #3fb24e;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 32px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  margin-left: 20px;
  float: right;
  right: 0 !important;
}


@media (max-width: 960px) {
  form {
    width: 100%;
  }

  .form .f-group .title {
    line-height: 20px;
  }
}

.form .last-users {
  align-self: stretch;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  background: radial-gradient(50% 50% at 50% 50%, #ffcc00, #ff6900);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  text-align: left;
  color: #3b3002;
}

.form .last-users .first {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.form .last-users .last {
  position: relative;
  color: #625006;
}

.form-info {
  margin: 0;
  padding: 0;
  color: #ffcc00;
  font-size: 20px;
  font-weight: 700;
  margin-top: 40px;
}

.form .f-group {
  gap: 32px;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.form .f-group .title {
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  font-size: 24px;
}

.form .f-group .f-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  text-align: left;
  font-size: 14px;
  color: #757575;
}

.form .f-group .f-container .uk-inline {
  width: 100%;
  height: auto;
}

.form .f-group .f-container .uk-inline .custom-icon {
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.form .f-group .f-container .uk-inline .uk-input {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
  border-radius: 4px;
  height: 60px;
  background-color: #fff;
  padding-left: 48px !important;
  /* İkon için yer bırak */
  font-size: 18px;
}

button {
  margin-top: 30px;
  align-self: stretch;
  box-shadow: 0px 4px 100px rgba(0, 255, 51, 0.5);
  border-radius: 4px;
  background: linear-gradient(-90deg, #15f32d, #358a17);
  padding: 16px 62px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
  transition: all .2s;
  border: none;
  color: #fff;
}

button:hover {
  box-shadow: 0px 4px 100px rgba(0, 255, 51, 0.922);
  background: linear-gradient(-90deg, #15f32d, #358a17);
}




.footer-logo {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 70px;
}


.logo-wrapper {
  width: 202.4px;
  position: relative;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.image-5-icon {
  width: 64px;
  position: relative;
  height: 64px;
  object-fit: cover;
}

.image-6-icon {
  width: 128px;
  position: relative;
  height: 64px;
  object-fit: cover;
}

.image-5-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  gap: 72px;
}

.footer-designer {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: 30.87px;
  color: #fff;
  font-family: 'SF Pro Display';
}